import type { WidgetDescriptor as ExternalWidgetDescriptor } from '@aurora/external-types/feature';
import type { FeaturedWidgetInstance } from '@aurora/shared-client/components/context/FeaturedWidgetContext/FeaturedWidgetContext';
import type { ProvisionalTextData } from '@aurora/shared-client/components/context/ProvisionalTextContext/ProvisionalTextContext';
import { ProvisionTextComponentContext } from '@aurora/shared-client/components/context/ProvisionalTextContext/ProvisionalTextContext';
import type { WidgetLocation } from '@aurora/shared-client/components/context/SectionWidgetContext';
import type { CSSPropertiesWithVars } from '@aurora/shared-client/helpers/styles/CSSPropertiesWithVarsHelper';
import type {
  OneColumnQuiltSection,
  QuiltComponent as QuiltComponentType,
  QuiltItem as QuiltItemType,
  QuiltList,
  QuiltWrapper,
  SectionEditLevel
} from '@aurora/shared-generated/types/graphql-schema-types';
import {
  QuiltItemType as QuiltItemTypeEnum,
  SectionLayoutType
} from '@aurora/shared-generated/types/graphql-schema-types';
import type {
  QuiltFragment,
  ThemeResultFragment
} from '@aurora/shared-generated/types/graphql-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import type { SectionLayout } from '@aurora/shared-types/quilts';
import { deepClone } from '@aurora/shared-utils/helpers/objects/ObjectHelper';
import { getLog } from '@aurora/shared-utils/log';
import isEqual from 'react-fast-compare';
import type {
  CustomComponentProps,
  CustomWidgetProps,
  WidgetProps
} from '../../components/common/Widget/types';
import type { EditContextInterface } from '../../components/context/EditContext/EditContext';
import type { QuiltWrapperWidgetLocation } from '../../components/context/QuiltWrapperWidgetContext/QuiltWrapperWidgetLocationContext';
import mainSideLayout from '../../components/page/Quilt/layout/main-side.layout.json';
import oneColumnLayout from '../../components/page/Quilt/layout/one-column.layout.json';
import sideMainLayout from '../../components/page/Quilt/layout/side-main.layout.json';
import threeColumnLayout from '../../components/page/Quilt/layout/three-column.layout.json';
import twoColumnLayout from '../../components/page/Quilt/layout/two-column.layout.json';
import type { SectionType } from '../../components/page/QuiltSection/QuiltSection';
import endUserComponentRegistry from '../../features/endUserComponentRegistry';
import { renderBorderStyle } from '../community/ThemeEditorElementsRulesHelper';
import type { PlaceholderSectionWidgetData, SectionWidgetData } from './PageEditorHelper';
import {
  isPageEditorSectionWidget,
  isPageEditorSectionWidgetPlaceholder,
  PageEditorSelectionType
} from './PageEditorHelper';

const log = getLog(module);
/**
 * Helper function used to determine if a quilt item is a list.
 *
 * @param item The individual item in a quilt items array.
 */
function isQuiltList(item: QuiltItemType): item is QuiltList {
  return item.type === QuiltItemTypeEnum.List;
}

/**
 * The location of the "selected widget" placeholder that displays when adding a new widget in PageEditor
 */
export interface PlaceholderWidgetLocation {
  /**
   * The sectionId for the placeholder
   */
  sectionId: string;
  /**
   * The columnId for the placeholder
   */
  columnId: string;
}

/**
 * Provides details about sections in quilts.
 */
interface QuiltSectionsData {
  /**
   * Collections of sections in list
   */
  sections: SectionType[];

  /**
   * Index of the main container for the section
   */
  position: number;
}

const layoutMap: Record<SectionLayoutType, SectionLayout> = {
  [SectionLayoutType.MainSide]: mainSideLayout,
  [SectionLayoutType.SideMain]: sideMainLayout,
  [SectionLayoutType.OneColumn]: oneColumnLayout,
  [SectionLayoutType.TwoColumn]: twoColumnLayout,
  [SectionLayoutType.ThreeColumn]: threeColumnLayout
};

function isQuiltV2(quilt: QuiltFragment) {
  const { container } = quilt;

  return !!container;
}

/**
 * Returns the quilts section data for the given quilt. It expects the quilt sections to be wrapped by a quilt list
 * container whose id is main. For quilts that does not follow the format, the sections are returned as null and position
 * as -1.
 * @param quilt quilt to get the sections data from.
 */
export function getQuiltSectionsData(quilt: QuiltFragment): QuiltSectionsData {
  if (isQuiltV2(quilt)) {
    return { sections: quilt.container.items as SectionType[], position: 0 };
  }
  const quiltItems: QuiltItemType[] = quilt.items;
  let sectionContainerIdx = -1;
  let idx = 0;
  while (idx < quiltItems.length) {
    const item: QuiltItemType = quiltItems[idx];
    if (isQuiltList(item) && item.id === 'main') {
      sectionContainerIdx = idx;
      break;
    }
    idx += 1;
  }

  if (sectionContainerIdx !== -1) {
    const sections: SectionType[] = (quiltItems[sectionContainerIdx] as QuiltList)
      .items as SectionType[];

    return {
      sections,
      position: sectionContainerIdx
    };
  }
  log.error(
    'Invalid quilt specification. The items inside of quilt list with id main should all be sections'
  );

  return {
    sections: null,
    position: sectionContainerIdx
  };
}

/**
 * Updates the sections of the quilt with the given sections list.
 * @param quilt the quilt to update.
 * @param sections the sections to update for the quilt.
 * @param sectionListIdx the index of the list container of the sections in the quilt.
 */
export function updateSections(
  quilt: QuiltFragment,
  sections: SectionType[],
  sectionListIdx: number
): QuiltFragment {
  const updatedQuilt = quilt;
  if (isQuiltV2(quilt)) {
    updatedQuilt.container.items = sections;
  } else {
    (updatedQuilt.items[sectionListIdx] as QuiltList).items = sections;
  }
  return updatedQuilt;
}

/**
 * Helper for retrieving sections from a quilt.
 */
export function getSections(quilt: QuiltFragment): SectionType[] {
  const { sections } = getQuiltSectionsData(quilt);
  return sections;
}

/**
 * Adds section to the specified position of the quilt. If position is not defined or specified, it will add the sections
 * to the end of the quilt.
 * @param quilt quilt to add the section.
 * @param sectionId section id for the new section.
 * @param position position of the newly added section.
 */
export function addSection(
  quilt: QuiltFragment,
  sectionId: string,
  position: number
): QuiltFragment {
  const emptySection: OneColumnQuiltSection = {
    id: sectionId,
    layout: SectionLayoutType.OneColumn,
    columnMap: {
      main: []
    }
  };

  const quiltClone = deepClone(quilt);

  const { sections, position: sectionListIdx } = getQuiltSectionsData(quiltClone);
  if (sections) {
    if (position >= 0 && position < sections.length) {
      sections.splice(position, 0, emptySection);
    } else if (position === sections.length) {
      sections.push(emptySection);
    }

    return updateSections(quiltClone, sections, sectionListIdx);
  }

  return quilt;
}

/**
 * Removes a section from the quilt.
 * @param quilt the quilt the section belongs to.
 * @param sectionId the id of the section to be removed.
 */
export function removeSection(quilt: QuiltFragment, sectionId: string): QuiltFragment {
  const quiltClone = deepClone(quilt);
  const { sections, position: sectionListIdx } = getQuiltSectionsData(quiltClone);

  if (sections) {
    return updateSections(
      quiltClone,
      sections.filter(section => section.id !== sectionId),
      sectionListIdx
    );
  }

  return quilt;
}

/**
 * Reorders the section of the quilt.
 * @param quilt the quilt to reorder the section.
 * @param currentPosition the current position of the section to reorder.
 * @param targetPosition the target position of the section to reorder.
 */
export function repositionSection(
  quilt: QuiltFragment,
  currentPosition: number,
  targetPosition: number
): QuiltFragment {
  const quiltClone = deepClone(quilt);
  const { sections, position: sectionListIdx } = getQuiltSectionsData(quiltClone);

  const result = [...sections];
  const [removed] = result.splice(currentPosition, 1);
  result.splice(targetPosition, 0, removed);

  return updateSections(quiltClone, result, sectionListIdx);
}

/**
 * Returns the deleted widgget texts from the section based on its location.
 * @param quilt the quilt the section belongs to.
 * @param sectionId the location sectionId of widget to be removed on quilt.
 */
export function getOverrideTextsFromRemovedSelection(
  quilt: QuiltFragment,
  sectionId: string
): ProvisionalTextData[] {
  const quiltClone = deepClone(quilt);
  const { sections } = getQuiltSectionsData(quiltClone);
  const currentSection = sections.find(section => section.id === sectionId);
  const textOverrides: ProvisionalTextData[] = [];
  Object.keys(currentSection.columnMap).forEach(key => {
    const items = currentSection.columnMap[key];
    if (Array.isArray(items)) {
      items.forEach(item => {
        const instanceId = item.props?.instanceId;
        if (instanceId !== undefined) {
          textOverrides.push({
            id: item.id,
            instanceId: instanceId,
            texts: [],
            context: ProvisionTextComponentContext.WIDGET
          });
        }
      });
    }
  });
  return textOverrides;
}

/**
 * Updates the quilt sections with the specified sections.
 * @param quilt the quilt to update the sections.
 * @param sections the sections to update.
 */
export function updateQuiltSections(quilt: QuiltFragment, sections: SectionType[]): QuiltFragment {
  const quiltClone = deepClone(quilt);
  const { position } = getQuiltSectionsData(quiltClone);

  if (position !== -1) {
    return updateSections(quiltClone, sections, position);
  }

  return quilt;
}

const sectionLayoutTypeColumnsMap: Record<SectionLayoutType, string[]> = {
  [SectionLayoutType.MainSide]: ['main', 'side'],
  [SectionLayoutType.TwoColumn]: ['column1', 'column2'],
  [SectionLayoutType.ThreeColumn]: ['column1', 'column2', 'column3'],
  [SectionLayoutType.SideMain]: ['side', 'main'],
  [SectionLayoutType.OneColumn]: ['main']
};

/**
 * Reorganizes components in columns when section layout changes.
 * If the new layout has more columns than the original the components will maintain its positions.
 * If the new layout has less columns than the original the components will be relocated in the first column.
 * @param section The section to update.
 * @param currentLayout The last layout selected in the section to update.
 */
export function updateComponentsInLayout(
  section: SectionType,
  currentLayout: SectionLayoutType
): SectionType {
  const updatedSection: SectionType = { ...section };
  const currentColumns: string[] = sectionLayoutTypeColumnsMap[currentLayout];
  const newColumns: string[] = sectionLayoutTypeColumnsMap[section.layout];

  if (!isEqual(currentColumns, newColumns)) {
    const columnMap = Object.fromEntries(newColumns.map(c => [c, []]));

    if (currentColumns.length > newColumns.length) {
      currentColumns.forEach(currentColumn => {
        columnMap[newColumns[0]] = [
          ...columnMap[newColumns[0]],
          ...section.columnMap[currentColumn]
        ];
      });
    } else {
      newColumns.forEach((newColumn, index) => {
        columnMap[newColumn] =
          index < currentColumns.length ? section.columnMap[currentColumns[index]] : [];
      });
    }

    updatedSection.columnMap = columnMap as {
      side: Array<QuiltComponentType>;
      main: Array<QuiltComponentType>;
      column1: Array<QuiltComponentType>;
      column2: Array<QuiltComponentType>;
      column3: Array<QuiltComponentType>;
    };
  }
  return updatedSection;
}

/**
 * Adds a widget to a section
 *
 * @param quilt the quilt
 * @param location the placeholder widget location
 * @param widgetId the widget id
 */
export function addWidget(
  quilt: QuiltFragment,
  location: PlaceholderWidgetLocation,
  widgetId: string
): { quiltClone: QuiltFragment; widgetIdx: number; instanceId: string } {
  const quiltClone = deepClone(quilt);
  const section = getSections(quiltClone).find(s => s.id === location.sectionId);
  const widget = endUserComponentRegistry.getWidgetDescriptor(widgetId);
  const widgetIdx = section.columnMap[location.columnId].length;

  // if the widget being added requires an instance id and is being added via page editor, add it now
  const instanceId: string | null = widget?.isInstanceIdRequired
    ? `${widgetId}-${Date.now()}`
    : null;
  section.columnMap[location.columnId].push({
    id: widgetId,
    ...(instanceId && { props: { instanceId } })
  });

  return { quiltClone, widgetIdx, instanceId };
}

/**
 * Adds a quilt wrapper widget to a section
 *
 * @param quiltWrapper the quilt wrapper
 * @param location the widget location
 * @param widgetId the widget id
 * @param props the widget properties
 */
export function addQuiltWrapperWidget(
  quiltWrapper: QuiltWrapper,
  location: QuiltWrapperWidgetLocation,
  widgetId: string,
  props?: WidgetProps | CustomWidgetProps | CustomComponentProps
): QuiltWrapper {
  const quiltWrapperClone = deepClone(quiltWrapper);
  const section = quiltWrapperClone[location.sectionId];

  section.items.push({
    id: widgetId,
    ...(!!props && { props })
  });

  return quiltWrapperClone;
}

/**
 * Adds the passed widget to the selection from the quilt edit context.
 *
 * @param editContext of the quilt section selection.
 * @param widgetId of the widget.
 * @param props to be passed to the widget.
 */
export function addWidgetAndUpdateQuilt(
  editContext: EditContextInterface,
  widgetId: string,
  props: WidgetProps | CustomWidgetProps | CustomComponentProps
): void {
  const { quilt, onChange, selection, setSelection } = editContext;
  if (isPageEditorSectionWidgetPlaceholder(selection)) {
    const quiltClone = deepClone(quilt);
    const section = getSections(quiltClone).find(s => s.id === selection.sectionId);
    const widgetIdx = section.columnMap[selection.columnId].length;

    section.columnMap[selection.columnId].push({
      id: widgetId,
      props
    });

    onChange(quiltClone);

    const nextSelection: SectionWidgetData = {
      type: PageEditorSelectionType.SECTION_WIDGET,
      widgetId,
      location: {
        sectionId: selection.sectionId,
        columnId: selection.columnId,
        widgetIdx
      },
      props: props,
      sectionEditLevel: selection.sectionEditLevel
    };

    setSelection(nextSelection);
  } else {
    log.error('Add widget called with invalid selected location %O', JSON.stringify(selection));
  }
}

/**
 * Removes the passed widget from the quilt edit context.
 *
 * @param editContext of the quilt section selection.
 * @param widgetName of the widget.
 */
export function removeWidgetAndUpdateQuilt(
  editContext: EditContextInterface,
  widgetName: string
): void {
  const { quilt, onChange, selection, setSelection } = editContext;
  const currentSelection = isPageEditorSectionWidget(selection)
    ? (selection.location as WidgetLocation)
    : (selection as PlaceholderSectionWidgetData);
  const quiltClone = deepClone(quilt);
  const section = getSections(quiltClone).find(s => s.id === currentSelection.sectionId);
  const widgetList = section.columnMap[currentSelection.columnId];
  const currentWidget = widgetList.find(widget => widget?.props?.widgetName === widgetName);
  widgetList.splice(widgetList.indexOf(currentWidget), 1);
  onChange(quiltClone);
  setSelection(null);
}

/**
 * Replaces a widget by another in a section.
 * @param quilt the quilt to replaces the widget.
 * @param location the widget location.
 * @param widgetId the id of the widget to put in the section.
 * @param widgetIdx the idx in the section of the widget to replace.
 * @param instanceId the instance id for the replacement widget
 */
function replaceWidget(
  quilt: QuiltFragment,
  location: PlaceholderWidgetLocation,
  widgetId: string,
  widgetIdx: number,
  instanceId?: string
): { quiltClone: QuiltFragment; widgetIdx: number } {
  const quiltClone: QuiltFragment = deepClone(quilt);
  const section: SectionType = getSections(quiltClone).find(s => s.id === location.sectionId);

  section.columnMap[location.columnId].splice(widgetIdx, 1, {
    id: widgetId,
    ...(instanceId && { props: { instanceId } })
  });

  return { quiltClone, widgetIdx };
}

/**
 * Replaces the section widget that is currently being edited with translationBundleOverride new section widget, updating the quilt in the
 * page preview area, and also replacing the Edit Widget configuration form displayed by this component
 *
 * @param replacementWidgetId replacement widget id
 * @param location location of the replacement
 * @param sectionEditLevel the edit level of the section
 * @param quilt quilt being worked on
 * @param nodeId the current context node id
 */
export function replaceSectionWidget(
  replacementWidgetId: string,
  location: WidgetLocation,
  sectionEditLevel: SectionEditLevel,
  quilt: QuiltFragment,
  nodeId: string
) {
  const widgetId: string = replacementWidgetId.startsWith('custom.widget')
    ? EndUserComponent.CUSTOM_COMPONENT_WIDGET
    : replacementWidgetId;
  const replacementWidgetDescriptor: ExternalWidgetDescriptor =
    endUserComponentRegistry.getWidgetDescriptor(widgetId);
  // if the widget being added requires an instance id and is being added via page editor, add it now
  const createdInstanceId: string | null = replacementWidgetDescriptor.isInstanceIdRequired
    ? `${replacementWidgetId}-${Date.now()}`
    : null;

  const { sectionId, columnId, widgetIdx } = location;
  const { quiltClone: updatedQuilt } = replaceWidget(
    quilt,
    { sectionId, columnId },
    replacementWidgetId,
    widgetIdx,
    createdInstanceId
  );

  const replacementWidgetData: SectionWidgetData = {
    type: PageEditorSelectionType.SECTION_WIDGET,
    widgetId: replacementWidgetId,
    location: {
      sectionId,
      columnId,
      widgetIdx
    },
    sectionEditLevel,
    props: {
      instanceId: createdInstanceId
    }
  };

  let createdFeaturedWidgetInstance: FeaturedWidgetInstance;
  if (
    replacementWidgetDescriptor.id === EndUserComponent.FEATURED_CONTENT_WIDGET ||
    replacementWidgetDescriptor.id === EndUserComponent.FEATURED_PLACES_WIDGET ||
    replacementWidgetDescriptor.id === EndUserComponent.FEATURED_IDEA_STATUSES_WIDGET ||
    replacementWidgetDescriptor.id === EndUserComponent.FEATURED_GUIDE_WIDGET
  ) {
    createdFeaturedWidgetInstance = {
      componentId: replacementWidgetDescriptor.id,
      instanceId: createdInstanceId,
      featuredItemIds: [],
      coreNodeId: nodeId
    };
  }

  return { updatedQuilt, nextSelection: replacementWidgetData, createdFeaturedWidgetInstance };
}

/**
 * Reorders a widget within a quilt
 * @param quilt the quilt to reorder a widget
 * @param currentPosition where it is the widget located. Only positioning info will be used.
 * @param targetPosition where will be the widget placed. Only positioning info will be used.
 */
export function repositionWidget(
  quilt: QuiltFragment,
  currentPosition: WidgetLocation,
  targetPosition: WidgetLocation
): QuiltFragment {
  const quiltClone = deepClone(quilt);

  const { sectionId, columnId, widgetIdx } = currentPosition;

  const {
    sectionId: targetSectionId,
    columnId: targetColumnId,
    widgetIdx: targetWidgetIdx
  } = targetPosition;
  const currentColumn: SectionType[] = getSections(quiltClone).find(s => s.id === sectionId)
    .columnMap[columnId];
  const targetColumn: SectionType[] = getSections(quiltClone).find(s => s.id === targetSectionId)
    .columnMap[targetColumnId];

  const [widget] = currentColumn.splice(widgetIdx, 1);
  targetColumn.splice(targetWidgetIdx, 0, widget);
  return quiltClone;
}

/**
 * Returns the text for the section with given id for the given keys.
 * @param id id of the section
 * @param sectionKey the key for the desired text.
 * @returns the text for the section with given id for the given keys.
 */
export function getSectionKey(id: string, sectionKey: 'title' | 'description'): string {
  return `section.${id}.${sectionKey}`;
}

/**
 * Sets the CSS variable: --lia-content-item-border based on layout
 *
 */
export function setContentItemBorderCssVar(
  theme: ThemeResultFragment,
  layoutId: string
): CSSPropertiesWithVars {
  const mainLayoutSet = ['main', 'column1', 'column2', 'column3'];

  const sideLayoutSet = ['side'];

  if (mainLayoutSet.includes(layoutId)) {
    const mainContentBorder = renderBorderStyle(theme?.border?.mainContent);
    return { '--lia-content-item-border': mainContentBorder };
  } else if (sideLayoutSet.includes(layoutId)) {
    const sideContentBorder = renderBorderStyle(theme?.border?.sideContent);
    return { '--lia-content-item-border': sideContentBorder };
  }
}

/**
 * Returns unique render state instance id of the widget based on its location on the page
 * This is only used for render.
 * @param widgetId
 * @param widgetLocation
 */
function getRenderStateWidgetInstanceId(widgetId: string, widgetLocation: WidgetLocation): string {
  return `${widgetLocation.sectionId}-${widgetLocation.columnId}-${widgetId}-${widgetLocation.widgetIdx}`;
}

export function getSectionLayout(layout: SectionLayoutType): SectionLayout {
  return layoutMap[layout];
}

export function getSectionWidgetRenderState(
  layout: SectionLayoutType,
  getWidgets: (columnId: string) => QuiltComponentType[],
  sectionWidgetsRenderState: Partial<Record<string, boolean>>,
  sectionId: string
): Partial<Record<string, boolean>> {
  const { columns } = getSectionLayout(layout);

  // stores the render state of each widget in the page using a unique id for the widget based on its location.
  columns.forEach(({ id: columnId }) => {
    const widgets: QuiltComponentType[] = getWidgets(columnId);
    widgets.forEach(({ id: componentId }, widgetIdx) => {
      if (componentId) {
        sectionWidgetsRenderState[
          getRenderStateWidgetInstanceId(componentId, { sectionId, columnId, widgetIdx })
        ] = true;
      }
    });
  });

  return sectionWidgetsRenderState;
}
